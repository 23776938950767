<template>
  <jaya-connection-layout text="Vérification de votre compte en cours...">
  </jaya-connection-layout>
</template>

<script>
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";

export default {
  components: {
    JayaConnectionLayout,

  },

  mounted() {
    let userInfos = this.$route.query
    this.$loading(true)
    this.$store.dispatch('account/register_confirmation', userInfos)
        .then(() => {
          this.$loading(false)
          this.$notify({
            time: 4000,
            color: "primary",
            title: "Bienvenue",
            text: "Bienvenue sur le site d'autoévaluation de la Coupe de France du Potager"
          })
          this.$router.push({name: 'coupe-de-france-du-potager'})
        })
        .catch(error => {
          this.$loading(false)
          if (error.response && error.response.data.detail === "Invalid signature") {
            this.$notify({
              time: 4000,
              color: "danger",
              title: this.$t('Error'),
              text: "Le lien de vérification est invalide, il est peut être périmé"
            })
          } else {
            this.$notify({
              time: 4000,
              color: "danger",
              title: this.$t('Error'),
              text: "Une erreur est survenue, veuillez réessayer plus tard"
            })
          }
        })
  },
}
</script>

<style lang="scss">
</style>
